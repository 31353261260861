
// The editor creator to use.
import CKEditor from '@/components/CKEditor.vue'
import { SET_CONTENT_REQUEST, CONTENT_REQUEST } from '@/store/actions/website_content.ts'

//import Banner from '@/components/Banner.vue'

export default {
    name: 'Keuzedelen_Informatie',
    data() {
        return {
            content_name: 'Keuzedelen_Informatie',
            mounted: false
        }
    },
    components: {
        CKEditor,
        //Banner
    },
    mounted: function() {
        this.mounted = true
    },
    methods: {
        set_content: function(new_content) {
            this.$store.dispatch(SET_CONTENT_REQUEST, {name: this.content_name, content: new_content}).then()
        },
        goToFaq() {
            this.$refs.faq.scrollTo()
        }
    },
    computed: {
        getContent: function() {
            if (!this.mounted) return
            let store = this.$store.state.website_content.content
            if (!(this.content_name in store)) return this.$store.state.website_content.status
            return store[this.content_name]
        },
    },
}
